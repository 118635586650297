import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import DefaultAvatar from '../images/default_avatar.png';
import RoleIcon from './RoleIcon';
import { AvatarGroup } from '@material-ui/lab';

const RTVAvatar = props => {
  if (props.multiple) {
    const { src, ...restOfProps } = props;
    return (
      <AvatarGroup spacing={'small'} max={3}>
        {src.map(media => (
          <Avatar
            key={media?.user_small}
            src={media?.user_small}
            {...restOfProps}
          >
            <img
              style={{ width: '110%' }}
              alt="default avatar"
              src={DefaultAvatar}
            />
          </Avatar>
        ))}
      </AvatarGroup>
    );
  }

  return props.user ? (
    <Badge
      overlap="circle"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      badgeContent={<RoleIcon user={props.user} />}
    >
      <Avatar {...props}>
        <img
          style={{ width: '110%' }}
          alt="default_avatar"
          src={DefaultAvatar}
        />
      </Avatar>
    </Badge>
  ) : (
    <Avatar {...props}>
      <img style={{ width: '110%' }} alt="default_avatar" src={DefaultAvatar} />
    </Avatar>
  );
};

export default RTVAvatar;
