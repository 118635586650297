import {
  LIKE_PRODUCT_REQUESTED,
  LIKE_PRODUCT_SUCCESSFULL,
  LIKE_PRODUCT_FAILED,
  UNLIKE_PRODUCT_REQUESTED,
  UNLIKE_PRODUCT_SUCCESSFULL,
  UNLIKE_PRODUCT_FAILED,
  CLEAN_FAVORITES_STATUS
} from '../actions/types';

const defaultState = {
  status: null,
  favouriteState: {
    product: '',
    favourite: false,
    error: false
  },
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LIKE_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: LIKE_PRODUCT_REQUESTED,
        favouriteState: { product: '', favourite: false, error: false }
      };
    }
    case LIKE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: LIKE_PRODUCT_SUCCESSFULL,
        favouriteState: {
          product: action.product,
          favourite: true,
          error: false
        }
      };
    }
    case LIKE_PRODUCT_FAILED: {
      return {
        ...state,
        status: LIKE_PRODUCT_FAILED,
        error: action.error,
        favouriteState:
          action.error.status === 409
            ? { product: action.product, favourite: true, error: true }
            : { product: action.product, favourite: false, error: true }
      };
    }
    case UNLIKE_PRODUCT_REQUESTED: {
      return {
        ...state,
        status: UNLIKE_PRODUCT_REQUESTED,
        favouriteState: { product: '', favourite: false, error: false }
      };
    }
    case UNLIKE_PRODUCT_SUCCESSFULL: {
      return {
        ...state,
        status: UNLIKE_PRODUCT_SUCCESSFULL,
        favouriteState: {
          product: action.product,
          favourite: false,
          error: false
        }
      };
    }
    case UNLIKE_PRODUCT_FAILED: {
      return {
        ...state,
        status: UNLIKE_PRODUCT_FAILED,
        error: action.error,
        favouriteState:
          action.error.status === 409
            ? { product: action.product, favourite: false, error: true }
            : { product: action.product, favourite: true, error: true }
      };
    }
    case CLEAN_FAVORITES_STATUS: {
      return {
        ...state,
        status: null
      };
    }
    default:
      return state;
  }
};
